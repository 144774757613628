import { Report } from '@/models/Report';
import { Component, Vue } from 'vue-property-decorator';

@Component<AKitchenSink>({})
export default class AKitchenSink extends Vue {
  // #region @Props
  // #endregion

  // #region @Refs
  // #endregion

  // #region Optional @PropSyncs
  // #endregion

  // #region Class properties

  protected currentTabWithTo = '/tabs/tab-2';

  protected selectInputReportModel = new Report();

  protected selectInputSelectedReport: null | Report = null;

  protected selectInputMultipleReportModel = new Report();

  protected selectInputMultipleSelectedReports: Report[] = [];

  protected selectInputAutocompleteReportModel = new Report();

  protected selectInputAutocompleteSelectedReport: null | Report = null;

  protected selectInputAutocompleteMultipleReportModel = new Report();

  protected selectInputAutocompleteMultipleSelectedReports: Report[] = [];

  // protected tabsWithTo: ATabInterface[] = [
  //   {
  //     label: 'Tab 1',
  //     to: { name: 'tabs-tab-1' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 2',
  //     to: { name: 'tabs-tab-2' },
  //   },
  //   {
  //     label: 'Tab 3',
  //     to: '/kitchen-sink/tab-3',
  //   },
  //   {
  //     label: 'Tab 4',
  //     to: '/kitchen-sink/tab-4',
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 1',
  //     to: { name: 'tabs-tab-1' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 2',
  //     to: { name: 'tabs-tab-2' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 3',
  //     to: '/kitchen-sink/tab-3',
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 4',
  //     to: '/kitchen-sink/tab-4',
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 1',
  //     to: { name: 'tabs-tab-1' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 2',
  //     to: { name: 'tabs-tab-2' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 3',
  //     to: '/kitchen-sink/tab-3',
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 4',
  //     to: '/kitchen-sink/tab-4',
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 1',
  //     to: { name: 'tabs-tab-1' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 2',
  //     to: { name: 'tabs-tab-2' },
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 3',
  //     to: '/kitchen-sink/tab-3',
  //     icon: 'mdi-account',
  //   },
  //   {
  //     label: 'Tab 4',
  //     to: '/kitchen-sink/tab-4',
  //     icon: 'mdi-account',
  //   },
  // ];

  // #endregion

  // #region Lifecycle Hooks / Init
  // #endregion

  // #region Class methods: Handlers

  protected onMenuItemClick(ref: string): void {
    const element = this.$refs?.[ref] as Element;

    if (! element) { return; }

    const topPos = (element.getBoundingClientRect().top + window.pageYOffset) - 120;

    window.scrollTo({
      top: topPos,
      behavior: 'smooth',
    });
  }

  // #endregion

  // #region Class methods: Helpers
  // #endregion

  // #region Async methods
  // #endregion

  // #region Getters & Setters
  // #endregion

  // #region @Watchers
  // #endregion
}

// #region Enums
// #endregion

// #region Types
// #endregion

// #region Interfaces
// #endregion
