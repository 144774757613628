var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atabix-kitchen-sink" }, [
    _c("h1", { staticClass: "atabix-kitchen-sink__title" }, [
      _vm._v("Kitchen Sink")
    ]),
    _c("ul", { staticClass: "atabix-kitchen-sink__menu" }, [
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("Title")
            }
          }
        },
        [_vm._v("Title")]
      ),
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("Buttons")
            }
          }
        },
        [_vm._v("Buttons")]
      ),
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("Card")
            }
          }
        },
        [_vm._v("Card")]
      ),
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("TextInput")
            }
          }
        },
        [_vm._v("TextInput")]
      ),
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("SelectInput")
            }
          }
        },
        [_vm._v("SelectInput")]
      ),
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("StatefulForm")
            }
          }
        },
        [_vm._v("StatefulForm")]
      ),
      _c(
        "li",
        {
          on: {
            click: function($event) {
              return _vm.onMenuItemClick("Table")
            }
          }
        },
        [_vm._v("Table")]
      )
    ]),
    _c(
      "div",
      [
        _c("h2", { ref: "Title", staticClass: "atabix-kitchen-sink__title" }, [
          _vm._v("Title")
        ]),
        _c(
          "ACard",
          { attrs: { title: "Default" } },
          [_c("ATitle", [_vm._v("Hello world!")])],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Slot" } },
          [_c("ATitle", { attrs: { text: "Hello world!" } })],
          1
        ),
        _c("ACard", { attrs: { title: "Empty" } }, [_c("ATitle")], 1)
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "h2",
          { ref: "Buttons", staticClass: "atabix-kitchen-sink__title" },
          [_vm._v("Buttons")]
        ),
        _c(
          "ACard",
          { attrs: { title: "Icon left" } },
          [
            _c(
              "AButton",
              {
                staticClass: "tw-bg-secondary-400 hover:tw-bg-secondary-300",
                attrs: { "prepend-inner-icon": "mdi-home", to: "/home" }
              },
              [_vm._v(" Hello world! ")]
            )
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Icon right" } },
          [
            _c(
              "AButton",
              {
                staticClass: "tw-bg-secondary-400 hover:tw-bg-secondary-300",
                attrs: { "append-inner-icon": "mdi-home", to: "/home" }
              },
              [_vm._v(" Hello world! ")]
            )
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Loading state" } },
          [
            _c(
              "AButton",
              {
                staticClass:
                  "tw-border-error-500 tw-text-error-500 hover:tw-bg-error-500 hover:tw-text-white",
                attrs: {
                  alternative: "",
                  loading: true,
                  "prepend-inner-icon": "mdi-home",
                  to: "/home"
                }
              },
              [_vm._v(" Hello world! ")]
            )
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Alternative icon left" } },
          [
            _c(
              "AButton",
              {
                staticClass:
                  "tw-border-error-500 tw-text-error-500 hover:tw-bg-error-500 hover:tw-text-white",
                attrs: {
                  alternative: "",
                  "prepend-inner-icon": "mdi-home",
                  to: "/home"
                }
              },
              [_vm._v(" Hello world! ")]
            )
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Alternative icon right" } },
          [
            _c(
              "AButton",
              {
                attrs: {
                  alternative: "",
                  "append-inner-icon": "mdi-home",
                  to: "/home"
                }
              },
              [_vm._v("Hello world!")]
            )
          ],
          1
        ),
        _c("ACard", { attrs: { title: "No-wrap (default)" } }, [
          _c(
            "div",
            { staticClass: "tw-w-72" },
            [
              _c(
                "AButton",
                {
                  attrs: {
                    alternative: "",
                    "append-inner-icon": "mdi-home",
                    to: "/home"
                  }
                },
                [_vm._v("Hello world!")]
              )
            ],
            1
          )
        ]),
        _c("ACard", { attrs: { title: "Wrap (whitespace-normal)" } }, [
          _c(
            "div",
            { staticClass: "tw-w-72" },
            [
              _c(
                "AButton",
                {
                  staticClass: "tw-whitespace-normal",
                  attrs: { "prepend-inner-icon": "mdi-home" }
                },
                [_vm._v("Hello world!")]
              )
            ],
            1
          )
        ]),
        _c(
          "ACard",
          { attrs: { title: "Text attribute" } },
          [
            _c("AButton", {
              attrs: { "prepend-inner-icon": "mdi-home", text: "Hello world!" }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "No icon" } },
          [_c("AButton", { attrs: { text: "Hello world!" } })],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "No text" } },
          [_c("AButton", { attrs: { "prepend-inner-icon": "mdi-home" } })],
          1
        ),
        _c("ACard", { attrs: { title: "Empty" } }, [_c("AButton")], 1)
      ],
      1
    ),
    _c("div", [
      _c("h2", { ref: "Card", staticClass: "atabix-kitchen-sink__title" }, [
        _vm._v("Card")
      ]),
      _c(
        "div",
        { staticClass: "atabix-kitchen-sink__example-line" },
        [
          _c("div", [_vm._v("Default:")]),
          _c(
            "ACard",
            {
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_c("ATitle", [_vm._v("Title!")])]
                  },
                  proxy: true
                },
                {
                  key: "actions",
                  fn: function() {
                    return [_vm._v("Actions!")]
                  },
                  proxy: true
                }
              ])
            },
            [_vm._v(" Hello world! ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "atabix-kitchen-sink__example-line" },
        [
          _c("div", [_vm._v("Props:")]),
          _c("ACard", {
            attrs: { text: "Hello world!", title: "title" },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function() {
                  return [_vm._v("Actions!")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "atabix-kitchen-sink__example-line" },
        [
          _c("div", [_vm._v("No actions:")]),
          _c("ACard", { attrs: { text: "Hello world!", title: "title" } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "atabix-kitchen-sink__example-line" },
        [
          _c("div", [_vm._v("No text:")]),
          _c("ACard", {
            attrs: { title: "title" },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function() {
                  return [_vm._v("Actions!")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "atabix-kitchen-sink__example-line" },
        [
          _c("div", [_vm._v("No title:")]),
          _c("ACard", {
            attrs: { text: "Hello world!" },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function() {
                  return [_vm._v("Actions!")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "atabix-kitchen-sink__example-line" },
        [_c("div", [_vm._v("Empty:")]), _c("ACard")],
        1
      )
    ]),
    _c(
      "div",
      [
        _c(
          "h2",
          { ref: "TextInput", staticClass: "atabix-kitchen-sink__title" },
          [_vm._v("TextInput")]
        ),
        _c(
          "ACard",
          { attrs: { title: "Default" } },
          [_c("ATextInput", { attrs: { placholder: "Naam", value: "Artan" } })],
          1
        ),
        _c("ACard", { attrs: { title: "Empty" } }, [_c("ATextInput")], 1)
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "h2",
          { ref: "SelectInput", staticClass: "atabix-kitchen-sink__title" },
          [_vm._v("SelectInput")]
        ),
        _c(
          "ACard",
          { attrs: { title: "Items" } },
          [
            _c("ASelectInput", {
              attrs: {
                items: [{ id: "artan", label: "Artan" }],
                label: "Naam",
                value: "artan"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Model" } },
          [
            _c("ASelectInput", {
              attrs: {
                "item-value": "uuid",
                "item-text": "uuid",
                label: "Report",
                model: _vm.selectInputReportModel
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(item) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            [
                              item.uuid
                                ? item.uuid.substr(-4).toUpperCase()
                                : "",
                              item.created_by_user && item.created_by_user.name
                                ? item.created_by_user.name
                                : "",
                              item.workflow
                            ].join(" - ")
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selectInputSelectedReport,
                callback: function($$v) {
                  _vm.selectInputSelectedReport = $$v
                },
                expression: "selectInputSelectedReport"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Model multiple" } },
          [
            _c("ASelectInput", {
              attrs: {
                "item-value": "uuid",
                "item-text": function(item) {
                  return [
                    item.uuid ? item.uuid.substr(-4).toUpperCase() : "",
                    item.created_by_user && item.created_by_user.name
                      ? item.created_by_user.name
                      : "",
                    item.workflow
                  ].join(" - ")
                },
                label: "Report",
                model: _vm.selectInputMultipleReportModel,
                multiple: true
              },
              model: {
                value: _vm.selectInputMultipleSelectedReports,
                callback: function($$v) {
                  _vm.selectInputMultipleSelectedReports = $$v
                },
                expression: "selectInputMultipleSelectedReports"
              }
            })
          ],
          1
        ),
        _c("ACard", { attrs: { title: "Empty" } }, [_c("ASelectInput")], 1)
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "h2",
          {
            ref: "AutocompleteInput",
            staticClass: "atabix-kitchen-sink__title"
          },
          [_vm._v("AutocompleteInput")]
        ),
        _c(
          "ACard",
          { attrs: { title: "Items" } },
          [
            _c("AAutocompleteInput", {
              attrs: {
                items: [{ id: "artan", label: "Artan" }],
                label: "Naam",
                value: "artan"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Model" } },
          [
            _c("AAutocompleteInput", {
              attrs: {
                "item-value": "uuid",
                "item-text": "uuid",
                label: "Report",
                model: _vm.selectInputReportModel
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(item) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            [
                              item.uuid
                                ? item.uuid.substr(-4).toUpperCase()
                                : "",
                              item.created_by_user && item.created_by_user.name
                                ? item.created_by_user.name
                                : "",
                              item.workflow
                            ].join(" - ")
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selectInputSelectedReport,
                callback: function($$v) {
                  _vm.selectInputSelectedReport = $$v
                },
                expression: "selectInputSelectedReport"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Model multiple" } },
          [
            _c("AAutocompleteInput", {
              attrs: {
                "item-value": "uuid",
                "item-text": function(item) {
                  return [
                    item.uuid ? item.uuid.substr(-4).toUpperCase() : "",
                    item.created_by_user && item.created_by_user.name
                      ? item.created_by_user.name
                      : "",
                    item.workflow
                  ].join(" - ")
                },
                label: "Report",
                model: _vm.selectInputMultipleReportModel,
                multiple: true
              },
              model: {
                value: _vm.selectInputMultipleSelectedReports,
                callback: function($$v) {
                  _vm.selectInputMultipleSelectedReports = $$v
                },
                expression: "selectInputMultipleSelectedReports"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Empty" } },
          [_c("AAutocompleteInput")],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "h2",
          { ref: "SelectInput", staticClass: "atabix-kitchen-sink__title" },
          [_vm._v("SelectInput (autocomplete)")]
        ),
        _c(
          "ACard",
          { attrs: { title: "Items" } },
          [
            _c("ASelectInput", {
              attrs: {
                items: [{ id: "artan", label: "Artan" }],
                label: "Naam",
                value: "artan"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Model" } },
          [
            _c("ASelectInput", {
              attrs: {
                "item-value": "uuid",
                "item-text": "uuid",
                label: "Report",
                model: _vm.selectInputReportModel
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(item) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            [
                              item.uuid
                                ? item.uuid.substr(-4).toUpperCase()
                                : "",
                              item.created_by_user && item.created_by_user.name
                                ? item.created_by_user.name
                                : "",
                              item.workflow
                            ].join(" - ")
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selectInputSelectedReport,
                callback: function($$v) {
                  _vm.selectInputSelectedReport = $$v
                },
                expression: "selectInputSelectedReport"
              }
            })
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "Model multiple" } },
          [
            _c("ASelectInput", {
              attrs: {
                "item-value": "uuid",
                "item-text": function(item) {
                  return [
                    item.uuid ? item.uuid.substr(-4).toUpperCase() : "",
                    item.created_by_user && item.created_by_user.name
                      ? item.created_by_user.name
                      : "",
                    item.workflow
                  ].join(" - ")
                },
                label: "Report",
                model: _vm.selectInputMultipleReportModel,
                multiple: ""
              },
              model: {
                value: _vm.selectInputMultipleSelectedReports,
                callback: function($$v) {
                  _vm.selectInputMultipleSelectedReports = $$v
                },
                expression: "selectInputMultipleSelectedReports"
              }
            })
          ],
          1
        ),
        _c("ACard", { attrs: { title: "Empty" } }, [_c("ASelectInput")], 1)
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "h2",
          { ref: "StatefulForm", staticClass: "atabix-kitchen-sink__title" },
          [_vm._v("StatefulForm")]
        ),
        _c(
          "ACard",
          { attrs: { title: "default" } },
          [
            _c(
              "AStatefulForm",
              [
                _c("ATextInput", { attrs: { label: "Naam", value: "Artan" } }),
                _c("ASelectInput", {
                  attrs: {
                    items: [{ id: "artan", label: "Artan" }],
                    label: "Naam",
                    value: "artan"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "ACard",
          { attrs: { title: "collapse-inputs" } },
          [
            _c(
              "AStatefulForm",
              { staticClass: "collapse-inputs" },
              [
                _c("ATextInput", { attrs: { label: "Naam", value: "Artan" } }),
                _c("ASelectInput", {
                  attrs: {
                    items: [{ id: "artan", label: "Artan" }],
                    label: "Naam",
                    value: "artan"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("ACard", { attrs: { title: "empty" } }, [_c("AStatefulForm")], 1)
      ],
      1
    ),
    _c(
      "div",
      [
        _c("h2", { ref: "Table", staticClass: "atabix-kitchen-sink__title" }, [
          _vm._v("Table")
        ]),
        _c(
          "ACard",
          { attrs: { title: "Table Link" } },
          [
            _c("ATableLink", {
              attrs: { to: "/kitchen-sink", value: "TableLink" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }